@use "/src/sass/import"

.container
	opcity: 1
	transition: opacity 2s linear

.container-hide
	opacity: 0

@keyframes highlight
	0%
		opacity: 0
	50%
		opacity: 1
	100%
		opacity: 0

.highlight
	@include import.background-color-primary
	animation: highlight 1s linear infinite
	border-radius: 999px