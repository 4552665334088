@use "/src/sass/import"

.left-menu-container
	width: import.$dashboard-left-menu-width
	overflow: hidden
	flex-shrink: 0
	flex-grow: 0


.left-menu-double
	width: import.$dashboard-left-menu-width * 2
	background-color: import.$col-grey-xlight
	height: 100vh
	display: flex
	flex-grow: 0
	flex-shrink: 0
	transition: transform 0.3s ease

.view-team
	transform: translateX(0)
	
.view-record
	transform: translateX(- import.$dashboard-left-menu-width)

.left-menu-view-container
	width: import.$dashboard-left-menu-width
	display: flex
	flex-grow: 0
	flex-shrink: 0

.left-menu-view
	$padding: 20px
	width: 100%
	padding: $padding $padding 0 $padding // bottom padding is added in scroll container
	display: flex
	flex-direction: column
