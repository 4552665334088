@use "/src/sass/import"

.record-list-scroll-container-outer
	// This scroll container is separate because of a bug in safari
	// it doesn't like `overflow: scroll` with `display: flex`
	@extend .hide-scrollbar

	// Hide scroll bar doesn't work (ie firefox), this moves it to the edge
	margin-right: -20px
	padding-right: 20px
	overflow-y: scroll

.record-list-scroll-container-inner
	display: flex
	flex-direction: column
	padding-bottom: 30px // when you hover a link, a little bar pops up on chrome on the bottom left

.record-list
	$padding: 4px
	padding: $padding
	display: flex
	flex-direction: column
	margin-bottom: 15px

.no-records-text
	text-align: center
	margin: 40px
	color: import.$col-grey-text

.view-archived
	margin: 20px 0px