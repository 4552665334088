@use "/src/sass/import"

.calendar-container
	display: flex
	justify-content: center

	margin-bottom: 20px
	cursor: pointer

.active-filters
	@include import.background-color-primary
	color: import.$col-white
	border-radius: 100px
	padding: 4px 15px
	max-width: 500px
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis



.button-row
	display: flex
	justify-content: space-between

