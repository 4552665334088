@use "/src/sass/import"

.create-pdf-button-container
	margin: 8px 0px 12px
	display: flex
	justify-content: space-between

.centered-button
	display: flex
	justify-content: center
	button
		border-width: 0px !important


.load-more-button-container
	display: flex
	justify-content: center
	align-items: flex-start
	margin-top: 10px

.load-more-button
	color: import.$col-grey-text
	border-color: rgba(0,0,0,0)
	&:hover
		background-color: rgba(0,0,0,0)
		@include import.border-color-primary
		@include import.color-primary

$width: 250px

