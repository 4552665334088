.selected-user-container
	display: flex
	align-items: center

.reset-icon
	margin-left: 10px

.textarea
	min-height: 60px
	resize: none
