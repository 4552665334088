@use "../common/panel/constants"
@use "/src/sass/import"

.container
	display: flex
	flex-direction: row
	width: 100%

.right-panel-container
	padding: 40px 20px 20px 26px
	box-sizing: border-box
	overflow: hidden
	background-color: import.$col-grey-xlight
	width: 290px
	display: flex
	flex-shrink: 0
	flex-direction: column
	height: 100vh
	overflow-y: scroll


@media (max-width: constants.$mobile-width)
	.externalForm.container
		flex-direction: column
	.externalForm
		.right-panel-container
			width: 100%
			height: auto

