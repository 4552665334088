@use "/src/sass/import"

.required-asterisk
	margin-left: 13px
	color: import.$col-red
	height: 35px
	display: flex
	align-items: center

.asterisk
	font-size: 1.8rem
	height: 34px

.text
	font-size: 1rem
.has-value
	color: import.$col-grey-light