@use "/src/sass/import"

.aggregated-table
	margin-top: 40px
	display: flex
	flex-direction: column
	align-items: center
	padding: 30px 7%

.title
	font-size: 2rem

.table
	width: 100%
.dark-row
	background-color: #f3f3f3

.cell
	padding: 15px
	border-left: 1px solid rgba(125,125,125,.1)

.cell:first-child
	border-left: none !important

.body-row
	cursor: pointer
	transition: all 0.15s
	&:hover
		@include import.background-color-primary($opacity: 0.6)

.header-row
	background-color: none
	font-weight: 600
	color: import.$col-grey-text

.cell
	cursor: pointer
	&:hover
		text-decoration: underline

.order-direction
	margin-left: 5px

.hide-icon
	opacity: 0


