@use "/src/sass/import"
	
.row
	padding: 6px 0px
	display: flex
	align-items: center
	color: import.$col-black
	&:hover .text
		@include import.color-primary

.icon
	font-size: 1.3rem
	line-height: 1.3rem
	// fix size so it doesn't change when icon changes
	width: 30px
	flex-shrink: 0
	flex-grow: 0

.icon-animation
	animation: icon 1s forwards
	animation-iteration-count: 2

.text
	flex-basis: 100%
	font-weight: 400

.selected
	@include import.color-primary

.disabled
	font-style: italic
	color: import.$col-grey-text

@keyframes icon
	0%
		opacity: 1
	50%
		opacity: 0
	100%
		opacity: 1

