@use "/src/sass/import"

.container
	height: calc(100vh - 220px)
	overflow-y: scroll
	display: flex
	flex-direction: column
	width: 100%

.no-scroll
	height: auto
	overflow-y: visible

.data-row
	border-bottom: 1px solid import.$col-grey-light
	cursor: pointer
	display: flex
	color: import.$col-black
	&:hover
		color: import.$col-black
		@include import.background-color-primary($opacity: 0.1)

.cell
	margin: 15px 20px
	width: 100%
	display: flex
	overflow-y: auto
	max-height: 200px
	overflow-x: auto
	&:hover
		color: import.$col-black