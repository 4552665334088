@use "/src/sass/import"

.container
	width: 100%
	align-self: stretch
	flex-grow: 1
	border-top: 1px solid col-grey-light

.map
	align-self: stretch

.link
	padding: 8px 18px
	font-size: 14px
	transition: background-color 200ms linear
	&:hover
		@include import.background-color-primary(0.1)

.record-name
	font-weight: bold

.link:not(:first-child)
	border-top: 1px solid import.$col-grey-light
	

\:global 
	.mapboxgl-popup-content
		padding: 0px
		box-shadow: 3px 4px 15px rgba(0,0,0,0.2)
	
	.mapboxgl-popup-tip
		display: none