@use "/src/sass/import"

.modal-blocker
	position: fixed
	z-index: 8000
	left: 0px
	top: 0px
	width: 100%
	height: 100%
	background-color: rgba(0,10,30,.3)
	display: flex
	justify-content: center
	align-items: center
	align-content: center
	flex-direction: column

.modal
	background-color: white
	box-shadow: 0 0 100px #222
	padding: 20px 30px
	max-width: 600px
	width: 100%
	margin-top: 45px
	max-height: calc(100vh - 90px)
	overflow-y: auto


.title
	margin-top: 0px

.bottom-buttons
	margin-top: 20px
	display: flex
	justify-content: space-between

.modal-cancel
	border: 0px
	color: import.$col-grey-text
	font-size: 1.3rem
	float: right
	margin-bottom: 0px
	margin-right: -30px
	margin-top: -10px
	&:before
		margin-right: 0px

	&:hover
		background-color: rgba(0,0,0,0)
		color: import.$col-black


