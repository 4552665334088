@use "/src/sass/import"

.org-container
	padding: 10px
	border-bottom: 1px solid import.$col-grey-border
	display: flex
	flex-direction: column
.org-row
	display: flex
	align-items: center
	justify-content: space-between

.left-col
	display: flex
	align-items: center

.org-name
	margin-right: 20px
	cursor: pointer
	line-height: 30px
	font-weight: 600
	&:hover
		text-decoration: underline

.id
	color: import.$col-grey
	margin-right: 5px
	width: 30px
	text-align: right

.count
	background-color: import.$col-grey-light
	border-radius: 50px
	margin-left: 10px
	padding: 2px 7px

