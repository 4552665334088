@use "/src/sass/import"

.container
	padding: 7px 20px 7px 10px
	display: flex
	flex-direction: column
	border-right: 1px solid import.$col-grey-light

.line
	text-overflow: ellipsis
	overflow: hidden
	white-space: nowrap