@use "/src/sass/import"

.container
	display: flex
	flex-direction: column

.title-row
	display: flex
	align-items: center

.archived
	display: flex
	align-items: center
	align-self: flex-end
	padding: 3px 26px
	background-color: #151970
	border-bottom-left-radius: 20px
	border-bottom-right-radius: 20px
	color: import.$col-white
	position: fixed
	opacity: 0.6
	margin-left: -12px
	margin-top: -50px
	font-size: 1.1rem

.icon-lock
	font-size: 1.2rem

.full-screen-form
	min-width: 1000px
	width: 100%