@use "/src/sass/import"
	
.title
	margin-bottom: 10px

.title-row
	display: flex
	justify-content: space-between

.sending-to-container
	display: flex
	align-items: center

.sending-to-title
	flex-shrink: 0

.sending-to-list
	display: flex
	flex-wrap: wrap
		
.email-item
	border-radius: 50px
	@include import.background-color-primary
	color: import.$col-white
	padding: 3px 10px
	margin: 5px
	
.label
	font-size: 1.3rem

.button-container
	margin-top: 20px
	display: flex
	justify-content: flex-end

.subtitle
	margin: 10px 0px
	font-weight: bold
	
