.link-container
	width: 100%

.container
	display: flex
	flex-direction: column
	width: 100%
	background-color: #5374d2
	white-space: nowrap
	color: white
	border-radius: 4px
	padding: 1px 4px
	margin-bottom: 4px
	box-sizing: border-box
	&:hover
		opacity: 0.8
