@use "/src/sass/import"

.container
	display: flex
	align-items: center

.icon
	font-size: 1.4em
	z-index: 0
	color: import.$col-grey-text
	width: 0px
	pointer-events: none
	&:before
		margin-right: 0px !important
		margin-left: 9px !important

.icon-input
	padding-left: 40px !important
	font-size: 1rem !important


.input
	-webkit-appearance:     none
	-moz-appearance:        none
	-ms-appearance:         none
	-o-appearance:          none
	appearance:             none
	outline: none
	font-size: 1.1rem
	-webkit-box-sizing: border-box
	-moz-box-sizing: border-box
	box-sizing: border-box
	transition: border-color 0.3s ease
	width: 100%
	font-family: 'Open Sans', sans-serif

	padding: 10px 10px
	border-radius: 4px
	border-style: solid
	border-width: 1px

	border-color: import.$col-grey-border

	&::placeholder
		color: import.$col-grey-border
	&:focus
		@include import.border-color-primary
	&:hover
		@include import.border-color-primary($opacity: 0.6)
