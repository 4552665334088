@use "/src/sass/import"

.list
	display: flex
	flex-direction: column
	width: 100%

.row
	display: flex
	justify-content: space-between
	padding-top: 8px
	padding-bottom: 8px
	margin-left: 10px
	margin-right: 20px
	border-bottom: 1px solid import.$col-grey-light
