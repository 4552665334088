@use "/src/sass/import"

.nav-bar-container
	width: import.$navbar-width
	background-color: import.$col-grey-light
	padding: 20px 0px
	flex-shrink: 0
	display: flex
	flex-direction: column
	align-items: stretch
	justify-content: space-between
	box-shadow: import.$col-grey-xlight 5px 0px 15px

.nav-bar-group
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center

.logo
	width: 50px
	margin-bottom: 15px
	&:before
		font-size: 3rem
		margin-right: 0px
	img
		width: 100%

.faded
	opacity: 0.3
