@use "/src/sass/import"

.container
	height: 100%
	width: 100%
	display: flex
	align-items: center
	justify-content: center
	background-color: import.$col-grey-xlight

.card
	width: 200px
	padding: 20px
	background-color: import.$col-white
	border-radius: 5px
	display: flex
	flex-direction: column