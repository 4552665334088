@use "/src/sass/import"

.answer-table
	width: 100%

.answer-table-row
	display: flex
	justify-content: space-around
	align-items: flex-start
	margin-right: 13px
	
.cell
	display: flex
	align-items: flex-start
	justify-content: center
	margin: 5px 7px 5px 0px

.table-field-title
	font-weight: 600
	flex-grow: 1
	margin-left: 4px
	display: flex
	flex-wrap: wrap
	justify-content: flex-start
	align-self: stretch
	align-items: flex-end
	color: import.$col-grey-text



