@use "/src/sass/import"

.container
	width: 100%
	overflow-y: scroll
	max-height: 240px
	overflow-x: hidden
	
.row
	display: flex
	flex-direction: column
	color: import.$col-black
	margin-bottom: 10px
	padding-right: 6px
	cursor: pointer
	&:hover
		@include import.color-primary

.top-row
	display: flex
	justify-content: space-between

.left-content
	font-weight: 600

.bottom-content
	color: import.$col-grey-text