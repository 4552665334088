@use "/src/sass/import"

.sort-handle
	width: 25px
	background-repeat: repeat
	margin: 5px 0px 10px -25px
	cursor: move
	transition: all 0.2s ease
	border-right: 4px solid transparent
	&:hover
		background-color: import.$col-grey-light
