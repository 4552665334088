@use "/src/sass/import"

.container
	display: flex
	justify-content: space-between
	align-items: center


.left
	width: 200px
	flex-grow: 0
	flex-shrink: 0

.right
	width: 100%
	flex-grow: 0