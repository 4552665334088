@use "/src/sass/import"

.heading
	text-align: center
	width: 100%

.download-container
	display: flex
	justify-content: center
.csv-available-data
	display: flex
	justify-content: space-between

.search-row
	display: flex
	align-items: center
	border-bottom: 1px solid import.$col-grey-border

.icon-search
	color: import.$col-grey-text
	font-size: 1.2rem
	margin-left: 5px
	&:before
		margin-right: 2px

.search-input
	border-bottom: none
	border-width: 0px
	font-size: 1rem
	line-height: 22px

.data-column
	width: 49%

.data-row
	cursor: pointer
	padding: 5px
	border-bottom: 1px solid import.$col-grey-border

	display: flex
	align-items: center
	&:hover
		@include import.background-color-primary($opacity: 0.6)

.data-row-input
	width: 20px

.label
	width: 100%

.selected
	@include import.background-color-primary
	color: import.$col-white
	&:hover
		@include import.background-color-secondary