@use "/src/sass/import"

.container
	width: 270px
	margin: 20px
	display: flex
	flex-direction: column

.title-container
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	flex-basis: 80px
	border-bottom: 2px solid import.$col-grey-border

.title
	text-align: center
	font-size: 1.3rem
	color: import.$col-black

.content-container
	margin: 10px
	display: flex
	align-items: center
	flex-direction: column