@use "/src/sass/import"

.container
	width: 100%

.autocomplete-container
	position: relative
	z-index: 5
	height: 0px
	overflow: visible

.autocomplete-container-inner
	display: flex
	flex-direction: column
	background-color: white
	box-shadow: -1px 4px 8px rgba(0, 0, 0, 0.15)
	border-radius: 5px

.autocomplete-item
	cursor: pointer
	padding: 8px 10px
	border-top: 1px solid import.$col-grey-border

	&:hover
		@include import.background-color-primary(0.1)
		transition: background 200ms linear


.autocomplete-title
	font-weight: bold