@use "/src/sass/import"

.container
	display: flex
	flex-direction: column
	align-items: center
	width: 100%
	height: 100vh
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 22px -7px
	z-index: 10



