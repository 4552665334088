@use "/src/sass/import"

.months-container
	display: flex
	align-items: center
	justify-content: center
	width: 100%

.month-cell
	flex-basis: 0
	flex-grow: 1
	display: flex
	align-items: center
	justify-content: center