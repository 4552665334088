@use "/src/sass/import"

.name
	font-weight: 400
.position
	font-weight: 400
	color: import.$col-grey
	font-size: 0.8rem
	

.no-name
	font-style: italic

.record
	padding: 10px 0px
	color: import.$col-black
	display: flex
	flex-direction: column
	&:hover .name
		@include import.color-primary

.record-selected
	.name
		@include import.color-primary

