@use "/src/sass/import"

.container
	display: flex
	justify-content: center
	flex-basis: 500px
	flex-grow: 10
	min-height: 100vh
	max-height: 100vh
	overflow-y: scroll

.inner
	max-width: 1000px
	width: 100%
	padding: 50px
	a
		@include import.color-primary
		font-weight: 400