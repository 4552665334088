@use "/src/sass/import"

.container-outer
	flex-grow: 0
	flex-shrink: 0
	width: import.$dashboard-left-menu-width

.container
	background-color: import.$col-grey-xlight
	height: 100vh
	overflow-y: auto
	padding: 30px
	display: flex
	flex-direction: column

.title
	font-weight: 600
	font-size: 1.6rem
	margin-bottom: 10px

.settings-group
	margin-bottom: 20px

.group-title
	font-weight: 400
	font-size: 1.3rem
	margin-top: 10px
	margin-bottom: 10px

.list-item
	padding: 10px 15px
	color: import.$col-black
	display: flex
	flex-direction: column
	&:hover
		@include import.color-primary

.list-item-active
	@include import.color-primary
