@use "/src/sass/import"

.custom-emails
	margin-bottom: 30px

.custom-email-container
	margin-top: 20px

.add-another-email-button
	margin-top: 20px

.email-list
	max-height: 350px
	overflow-y: auto

.about-text
	display: flex
	align-items: center

.button-container
	margin-top: 20px
	display: flex
	justify-content: flex-end

.custom-input
	font-size: 1rem !important
	padding: 6px 16px !important
