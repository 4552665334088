@use "/src/sass/import"

.container
	margin: 20px 0px 0px 0px
	display: flex

.name
	font-size: 2.1rem
	line-height: 2.3rem
	font-weight: 600

.position
	margin-top: 10px
	color: import.$col-grey-text
	font-size: 20px

.picture
	display: flex
	margin: 0px 10px
	height: 140px
	width: 140px
	background-color: #f8f8f8
	background-position: center
	background-repeat: no-repeat
	background-size: contain
