@use "/src/sass/import"

.upload-button
	max-width: 180px

.image-container
	width: 100%
	display: flex
	flex-direction: column

.image
	width: 100%
	height: 30vw
	min-height: 200px
	max-height: 500px
	background-color: rgba(0,0,0,0.04)
	background-size: contain
	background-position: center
	background-repeat: no-repeat


.input
	display: none

.cancel
	margin-bottom: -37px
	margin-right: 8px
	padding: 3px 7px
	align-self: flex-end
	z-index: 2
	&:before
		margin-right: 0px