@use "/src/sass/import"

.external-link
	display: flex
	flex-direction: column

.url
	font-size: 0.8rem !important
	width: 100%
	background-color: import.$col-grey-light
	
.link-button-row
	display: flex
	align-items: flex-start