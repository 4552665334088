@use "/src/sass/import"
	
.container
	display: flex
	width: 100%
	border-top: 1px solid import.$col-grey-light
	box-sizing: border-box
	line-height: 1.4

.container-header
	background-color: import.$col-grey-light

.cell
	padding: 15px 20px

.cell-priority
	@extend .cell
	flex-basis: 85px
	flex-grow: 0

.cell-type
	@extend .cell
	flex-basis: 90px
	flex-grow: 0

.cell-signature
	@extend .cell
	flex-basis: 100px
	flex-grow: 0

.cell-reminder
	@extend .cell
	flex-basis: 200px
	flex-grow: 1

.cell-name
	@extend .cell
	flex-basis: 240px
	text-overflow: ellipsis
	flex-grow: 4

.cell-action
	@extend .cell
	flex-basis: 70px
	text-align: right
	flex-grow: 0

