@use "/src/sass/import"
@use "./constants"

.panel-box-shadow
	box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 22px -7px
	z-index: 10

.panel-inner
	padding: 50px 7% 80px
	display: flex
	flex-direction: column

.no-padding
	padding: 0px

.panel
	width: 100%
	display: flex
	flex-direction: column
	background-attachment: local
	z-index: 10
	min-height: 100vh

.panel-container-common
	background-color: import.$col-white
	min-height: 100vh

.panel-container-thin
	@extend .panel-container-common
	min-width: 400px
	width: 100%
	max-width: 700px

.panel-container-wide
	@extend .panel-container-common
	min-width: 600px
	width: 100%
	max-width: 800px

.panel-container-full-width
	@extend .panel-container-common
	min-width: 500px
	width: 100%

.panel-container
	@extend .panel-container-common
	min-width: 600px
	width: 100%
	max-width: 1500px

.panel-scroll-container
	overflow-y: auto
	width: 100%
	display: flex
	align-items: center
	height: 100vh
	flex-direction: column
	background-color: import.$col-white

// Waring this number is duplicated for form
@media (max-width: constants.$mobile-width)
	
		.removeScrollForMobile.panel-scroll-container
			height: inherit
			overflow-y: initial
		
		.panel-box-shadow
			box-shadow: none
		
