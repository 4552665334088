@use "/src/sass/import"

.icon-container
	margin-left: 10px

.icon-container
	margin-left: 0px

.icon-container-active
	@include import.color-primary-important
