@use "/src/sass/import"

.container
	display: flex
	width: 100%
	background-color: import.$col-grey-light
	height: 100vh

.content-container
	display: flex
	align-items: center
	flex-direction: column
