.bottom-row
	margin-top: 20px
	display: flex
	justify-content: space-between
	width: 100%

.button-group
	display: flex
	justify-content: center
	button
		margin-left: 10px