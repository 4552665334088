@use "/src/sass/import"

.container
	width: 100%
	display: flex
	justify-content: space-between
	align-items: center
	color: import.$col-black
	padding: 10px 0
	&:hover
		opacity: 0.8
		color: initial

.text-container
	flex-grow: 1
	display: flex
	justify-content: space-between

.left-content
	flex-grow: 1

.right-content
	color: import.$col-grey-text
	margin-left: 20px
	flex-shrink: 0