@use "/src/sass/import"

.teams-container
	display: flex
	flex-direction: column

.team
	display: flex
	align-items: center
	margin: 5px 0 5px 20px
	justify-content: space-between

.left-col
	display: flex
	align-items: center

.team-name
	margin-right: 20px

.id
	color: import.$col-grey
	margin-right: 5px
	width: 30px
	text-align: right

