@use "/src/sass/import"

.team-dropdown
    background-color: white
    position: absolute
    padding: 15px
    margin-left: -5px
    margin-top: 10px
    max-height: 70vh
    overflow-y: auto
    left: import.$navbar-width
    border-radius: 4px
    border: 1px solid #d9d9d9
    border-left-width: 3px
    @include import.border-color-primary($side: 'left')
    box-shadow: rgba(0,0,0,.1) 11px 0px 11px
    transition: opacity 0.5s ease
    flex-direction: column
    display: flex
    z-index: 100

.team-dropdown-hover
    opacity: 0
    visibility: hidden

.icon
    display: inline-block
    font-size: 2.3rem

    &:before
        margin: 0 !important

.link-container
    width: 100%
    
.link
    width: 100%
    display: flex
    justify-content: center
    color: import.$col-black
    cursor: pointer
    transition: all 0.2s ease
    margin: 2px 0
    padding: 10px 0
    &:hover .icon
        @include import.color-primary

.link-small
    padding: 3px 0
    color: import.$col-grey-text
    transform: scale(0.63)

.link-active
    @include import.color-primary


.container
    width: 100%
    display: flex
    justify-content: center

    &:hover .team-dropdown-hover
        opacity: 1
        visibility: visible
        transform: translateX(5px)

.link-primary
    @include import.background-color-primary
    color: white !important
    border-radius: 5px
    padding: 3px
    line-height: 40px
    width: 49px
    margin: 20px auto 0px

.link-primary-icon
    color: white !important



