@use "/src/sass/import"

.container
	display: flex
	align-items: center
	
	border-radius: 5px
	background-color: import.$col-white
	padding: 10px 10px
	margin-bottom: 10px
	box-shadow: 0.6px 1.3px 1.3px hsla(0deg, 0%, 0%, 0.38)
	
	// & + & 
		// border-top: 1px solid import.$col-grey-medium

.right-content
	width: 100%

.top-row
	display: flex
	align-items: center
	justify-content: space-between
	width: 100%

.icon-container
	display: flex
	align-self: center
	align-items: center
	margin-right: 10px
	border-radius: 14px
	padding: 4px
	border: 1px solid import.$col-grey-border
	background-color: import.$col-white

.complete-icon-container
	@include import.border-color-primary(0.2) 
	@include import.background-color-primary(0.7)

.complete-icon
	color: import.$col-white

.incomplete-icon
	opacity: 0

.button-container
	display: flex
	justify-content: center

	width: 100%
	border-top: 1px solid import.$col-grey-light
	margin-top: 5px
	padding-top: 5px

.name
	// margin-left: 10px