@use "/src/sass/import"
	
.form-field
	margin: 45px 0px 20px
.field-subsection-heading
	border-bottom: 1px solid import.$col-black
.inline-field-row
	width: 100%
	display: flex
	flex-wrap: wrap
	align-items: center
	justify-content: space-between
	border-bottom: 1px solid import.$col-grey-light

.field-content-container
	display: flex
.field-content
	width: 100%
.more
	display: flex
	cursor: pointer
	color: import.$col-grey-text
	flex-direction: column
	justify-content: center
	align-items: center
	margin-left: 10px
	padding: 0px 0px
	border: 0px
	border-right: 2px solid
	@include import.border-color-primary
	transition: all 0.3s ease
	border-radius: 4px
	background-image: linear-gradient(90deg, rgba(0,0,0,0), import.$col-grey-light)
	&:hover
		background-color: import.$col-grey-light
		color: import.$col-black

.more-button
	border-top-right-radius: 0px
	border-bottom-right-radius: 0px

.icon-add
	padding: 0
	margin-top: 10px
	margin-left: 10px
	height: 30px
	width: 30px
	border-radius: 15px
	display: flex
	align-items: center
	font-size: 1.2rem
	justify-content: center
	&:before
		height: 28px
		margin-top: 8px !important
		margin-left: 8px !important



