@use "/src/sass/import"

.simple-icon-button
	display: inline-block
	&:hover
		.hover-container-outer
			display: block

.simple-icon
	display: flex
	align-items: center
	cursor: pointer
	font-size: 1.6rem
	color: import.$col-grey-text
	transition: color 0.2s linear
	padding: 0px 8px
	margin: 0px !important
	&:before
		margin: 4px !important
	&:hover
		color: import.$col-black

.small-icon
	font-size: 1rem
	padding: 2px
	line-height: 9px

.no-hover
	cursor: default