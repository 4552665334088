@use "/src/sass/import"

.select-general
	border-top: 1px solid import.$col-grey-border
	padding-top: 10px
	width: 100%

.org-name
	cursor: default
	font-size: 0.9rem
	padding-top: 3px
	color: import.$col-grey-text
	font-weight: 500