$navbar-width: 65px
$dashboard-left-menu-width: 330px

$compact-width: 1150px

$input-default-width: 280px

$col-primary-default: #2dafd6
$col-secondary-default: #72679e
$col-navbar-default: #333339

$col-black: #000000
$col-grey: #888888
$col-grey-text: #999999
$col-grey-medium: #cccccc
$col-grey-border: #cccccc
$col-grey-light: #ebebeb
$col-grey-xlight: #f6f6f6
$col-white: #ffffff

$col-red: #ee6666
$col-red-light: #ffeeee
$col-green: #33a544
$col-green-light: #eeffee
$col-blue: #5462dc
$col-navy: #1d222c
$col-orange: #ec9e37