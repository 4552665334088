@use "/src/sass/import"

.row
	display: flex
	align-items: center
	justify-content: space-between
	width: 100%

.delta-text
	min-width: 200px

.send-to-container
	width: 250px
	display: flex

.pillcontainer
	display: flex
	align-items: center
	&:hover .pill-delete
		opacity: 1

.pill-delete
	padding: 3px 5px
	cursor: pointer
	opacity: 0

.pill
	border-radius: 50px
	padding: 1px 15px
	color: import.$col-white

.everyone
	@extend .pill
	background-color: #48aeed

.user
	@extend .pill
	background-color: #a45dce

.nobody
	@extend .pill
	background-color: #bdbdbd

.add-people
	cursor: pointer
	margin: 5px 0 0 10px
	&:hover
		font-weight: 500

.user-select-container
	margin-top: 5px


.delete-icon
	margin-left: 10px
	cursor: pointer
	font-size: 1.15rem
	&:hover
		color: import.$col-red