@use "/src/sass/import"
	
.title
	cursor: pointer
	font-size: 1.3rem
	margin: 12px 0px 6px
	&:hover 
		@include import.color-primary

.forms
	overflow-y: hidden
	display: flex
	flex-direction: column
	max-height: 0px
	transition: max-height 500ms ease

.forms-show
	max-height: 700px
