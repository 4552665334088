@use "/src/sass/import"

.user
	margin: 12px 0
	display: flex
	flex-direction: column

.top-row
	display: flex
	align-items: center

.name
	font-weight: 400
	font-size: 1.0rem
	color: import.$col-black

.email
	color: import.$col-grey-text
	margin-top: 3px
	text-overflow: ellipsis
	overflow: hidden

.is-admin
	color: import.$col-white
	background-color: import.$col-blue
	border-radius: 20px
	padding: 2px 10px
	margin-left: 10px
	font-size: 0.8rem

.user-active
	@include import.color-primary