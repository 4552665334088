@use "/src/sass/import"

.dropzone-answer
	display: inline-block

.dropzone-inner
	cursor: pointer
	display: flex
	align-items: center
	justify-content: center
	background-color: import.$col-grey-xlight
	border-radius: 3px
	padding: 6px 10px

.icon
	font-size: 1.4rem

.file
	display: flex
	flex-wrap: wrap
	justify-content: center
	align-items: center

.filename
	cursor: pointer
	display: flex
	flex-wrap: wrap
	justify-content: center
	align-items: center
	&:hover
		text-decoration: underline
	word-break: break-all

.remove-icon
	margin: 5px 0px 5px 15px
	font-size: 0.9rem
	color: import.$col-grey-text
	cursor: pointer
	&:hover
		color: import.$col-red


