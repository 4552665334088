@use "/src/sass/import"

.container
	display: flex
	flex-direction: column

.signature-image-container
	background-color: rgba(0,0,0,0.04)
	padding: 5px
	align-self: flex-start
	border: 2px solid import.$col-grey-text
	border-radius: 5px
	width: 100%
	max-width: 450px

.signature-image
	background-size: contain
	background-position: center
	background-repeat: no-repeat
	width: 100%
	max-width: 450px
	height: 70px