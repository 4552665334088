@use "/src/sass/import"
.container
	display: flex
	flex-direction: column
.logo
	width: 100%
	height: 70px
	background-size: contain
	background-repeat: no-repeat
	background-position: center
	margin-bottom: 20px

.team-name-row
	display: flex
	justify-content: space-between
	align-items: center
	margin-top: -10px
	min-height: 55px

.icon-container
	margin-right: -15px

.team-name
	font-weight: 600
	font-size: 1.3rem

.upload-logo-container
	background-color: #e0e0e0
	margin: 0px 25px
	padding: 10px 0px
	display: flex
	justify-content: center
	align-items: center
	color: import.$col-grey-text
	margin-bottom: 20px
	border-radius: 10px

.icon
	font-size: 2rem