@use "/src/sass/import"

.container 
	color: import.$col-grey-text
	border-radius: 5px
	min-width: 100px
	display: flex
	justify-content: center
	padding: 3px 3px
	box-shadow: inset 1px 1px 3px rgb(0 0 0 / 20%)
	background-color: rgba(0, 0, 0, 0.04)

.icon-tick
	color: import.$col-green
	font-size: 1.00rem
	margin-right: 4px
	margin-left: -5px



