@use "/src/sass/import"

.icon-container
	display: flex
	align-items: center
	justify-content: center
	width: 32px
	height: 32px
	border-radius: 50px
	cursor: pointer
	background-color: import.$col-grey-light
	transition: background-color 0.2s linear
	&:hover
		background-color: import.$col-grey-border