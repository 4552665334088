@use "/src/sass/import"

.back-button
	align-self: center
	margin-top: 20px
	margin-bottom: 30px

.title
	font-weight: 600
	font-size: 1.3rem
	display: flex
	align-items: center
	margin-bottom: 5px
	color: import.$col-black
	&:hover
		color: inhert

.line-spacer
	border-bottom: 3px solid import.$col-grey-light
	margin: 16px -20px 16px