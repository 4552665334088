@use "/src/sass/import"

.container
	display: flex
	flex-direction: column
	border-left: 1px solid import.$col-grey-border
	padding-left: 10px

.user-list
	display: flex
	flex-direction: column
	max-height: 400px
	overflow-y: auto

.search
	max-width: 300px
	margin-bottom: 10px