@use "/src/sass/import"

.email-row
	margin: 5px
	display: flex
	justify-content: space-between

.email
	overflow: hidden
	text-overflow: ellipsis

.created
	color: import.$col-grey-text

.no-content
	color: import.$col-grey-text