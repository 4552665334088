@use "/src/sass/import"

.team-item
	display: flex
	align-items: center
	margin: 15px 0px

.not-editable
	opacity: 1
	pointer-events: none
	border-width: 0px !important

.spinner-container
	margin: 0px 8px

.icon
	cursor: pointer
	border: 1px solid import.$col-black
	color: import.$col-black
	width: 24px
	height: 24px
	border-radius: 24px
	margin-right: 10px
	transform: scale(1.2)
	transition: all 0.2s ease
	&:before
		margin-right: 0
		position: relative
		top: 1px
		left: 4px
	&:hover
		color: import.$col-green
		transform: scale(1.4)

.matched
	background-color: import.$col-green
	color: import.$col-white
	border: 1px solid rgba(0,0,0,0)
	&:hover
		color: import.$col-white

