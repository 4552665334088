@use "/src/sass/import"

.deletable-row
	display: flex
	width: 100%

.delete-answer-button-container
	width: 24px
	margin-right: -24px
	display: flex
	justify-content: center
	align-items: flex-start

.delete-answer-button
	color: import.$col-grey-text
	background-color: rgba(0,0,0,0)
	border-color: rgba(0,0,0,0)
	border-radius: 12px
	font-size: 0.7em
	padding: 0px
	margin-top: 13px
	height: 24px
	width: 24px
	&:before
		margin-right: 0px !important
	&:hover
		color: import.$col-white
		background-color: import.$col-red

.deleted-answer
	width: 100%
	display: flex
	justify-content: flex-end
	align-items: center
	height: 40px
	margin: 5px 0
	border-radius: 4px
	padding-right: 10px
	color: import.$col-grey-text
	background-color: import.$col-grey-xlight
	border: 1px solid import.$col-grey-border
	cursor: pointer
	transition: all 0.8s ease
	&:hover
		background-color: import.$col-grey-xlight
		color: import.$col-black
