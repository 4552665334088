@use "/src/sass/import"

.container
	display: flex
	align-items: center
	&:hover
		.delete-button
			opacity: 1

.delete-button
	margin-left: 10px
	opacity: 0
	cursor: pointer

.user
	padding: 3px 15px
	border-radius: 100px
	background-color: import.$col-grey-light
	margin: 3px 0px 3px
	cursor: pointer
	&:hover
		background-color: import.$col-grey-border