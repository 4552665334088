.save-row
	width: 100%
	display: flex
	align-items: center
	justify-content: space-between
	flex-direction: column


.add-row
	width: 100%
	display: flex
	align-items: center
	justify-content: space-between