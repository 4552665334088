@use "/src/sass/import"

.button-row
	display: flex
	width: 100%
	justify-content: space-between
	align-items: center

.save-button-container
	display: flex
	align-items: center
	
.save-button
	margin-right: 20px
