@use "sass:map"
@use "./_font-icons" as font

@mixin icon($position: before, $icon: false, $styles: true)
	@if $position == both
		$position: 'before, &:after'

	&:before
		margin-right: 8px

	&:#{$position}
		@if $icon
			content: "#{map.get(font.$font-icons, $icon)}"

		@if $styles
			speak: none
			font-style: normal
			font-weight: normal
			font-family: Icons

		@content

.even-icon-margin
	&:before
		margin: 8px