@use "/src/sass/import"

.container
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center

.pie
	display: flex
	justify-content: center
	align-items: center
	margin: 20px
	width: 200px

.pie-labels
	display: flex
	justify-content: center
	flex-wrap: wrap

.pie-label
	display: flex
	align-items: center
	padding: 4px 10px 4px 2px
	display: flex
	cursor: pointer
	border-radius: 4px
	transition: all 0.2s ease

.label-dot
	width: 10px
	height: 10px
	flex-shrink: 0
	border-radius: 50%
	transition: all 0.2s ease
	margin: 10px

.pie-label:hover>.label-dot
	transform: scale(1.8)
.pie-label:hover
	transform: scale(1.05)


.pie-label.selected>.label-dot
	background-color: import.$col-white !important

.pie-label.selected>.label-text
	color: import.$col-white

.pie-detailed-values-container
	height: 0px
	width: 300px
.pie-detailed-values
	z-index: 99
	position: relative
	top: 10px
	background-color: import.$col-white
	box-shadow: 0px 0px 15px rgba(0,0,0,0.2)
	border: 1px solid import.$col-grey-border
	padding: 10px

	max-height: 300px
	overflow-y: scroll

.pie-detail
	margin: 10px 0px

.form-link-row
	display: flex
	flex-direction: column
	margin: 10px
	width: 100%
	justify-content: space-between
	color: import.$col-black
	&:hover
		@include import.color-primary

.top-content
	font-weight: 600

.bottom-content
	color: import.$col-grey-text

.no-data
	margin-top: 40px
	margin-bottom: 40px

