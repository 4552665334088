@use "/src/sass/import"

.tab-container
	display: flex
	flex-direction: row
	overflow-x: auto

.tab
	padding: 5px 19px 5px
	transition: all 0.2s
	margin-top: 5px
	margin-right: 5px
	font-size: 1.1rem
	text-wrap: nowrap
	white-space: nowrap
	display: flex
	color: import.$col-black
	border-top-left-radius: 6px
	border-top-right-radius: 6px
	box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.06)
	border-bottom: 2px solid rgba(0,0,0,0.01)
	border-right: 1px solid rgba(0,0,0,0.07)
	border-top: 1px solid rgba(0,0,0,0.04)
	&:hover
		@extend .active
		@include import.color-primary
		opacity: 1
	background-color: #e2e2e2

.active
	background-color: import.$col-white
	border-bottom: 3px solid import.$col-white

