@use "/src/sass/import"
	
.container
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	height: 100%
	color: import.$col-black
	margin: 20px 0px
	flex-grow: 0
	&:hover
		@include import.color-primary

.value
	font-size: 2rem

.subtitle
	font-size: 1.2rem
	color: import.$col-grey-text

.disabled-link
	pointer-events: none
	cursor: default