@use "sass:math"
@use "/src/sass/import"

@keyframes pharosspin
	100%
		transform: rotate(360deg)

@mixin spinner($size, $col1: rgba(var(--color-primary),0.9), $col2: rgba(var(--color-secondary),0.1), $speed: 3s, $border-width: 0)

	@if $border-width == 0
		border-style: solid
		border-width: math.div($size, 12)
		//for css variable compatibility
		border-color: import.$col-secondary-default
		border-color: $col2
	@else
		border-style: solid
		border-width: $border-width
		border-color: import.$col-secondary-default
		border-color: $col2

	width: $size
	height: $size
	border-radius: $size
	border-top-color: import.$col-primary-default
	border-top-color: $col1
	animation: pharosspin $speed infinite linear


.centered-spinner-container
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	.spinner
		margin: 20px
		@include spinner(40px, $speed: 1s)
		
.large-spinner-container
	display: flex
	align-items: center
	justify-content: center
	height: 50vh
	.spinner
		@include spinner(80px, $speed: 2s, $border-width: 3px)

.spinner-container
	.spinner
		margin: 20px
		@include spinner(40px, $speed: 1s)

.small-spinner-container
	display: inline-flex
	justify-content: center
	align-items: center
	.spinner
		@include spinner(15px, $speed: 1s, $border-width: 2px)

@mixin loading-bar($height, $speed)
	height: $height
	width: 100%
	position: relative
	overflow: hidden
	&:before
		display: block
		position: absolute
		content: ""
		left: -100px
		width: 100px
		height: $height
		@include import.background-color-primary
		filter: brightness(65%) // hack because its on buttons + white...
		animation: pharosloadingbar $speed linear infinite

.loading-bar
	@include loading-bar(4px, 3s)

.loading-bar-small
	@include loading-bar(2px, 2s)


@keyframes pharosloadingbar
	from 
		left: -20px
		width: 10%
		opacity: 0.1
	10%
		opacity: 1
	50%
		width: 70%
	70%
		width: 90%
	80%
		left: 70%
	95%
		left: 90%
		opacity: 1
	to
		opacity: 0.2
		left: 100%
