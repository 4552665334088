@use "./_const" as const

//color
@mixin color-primary($opacity: 1)
    color: rgba(const.$col-primary-default, $opacity)
    color: rgba(var(--color-primary), $opacity)

@mixin color-primary-important($opacity: 1)
    color: rgba(const.$col-primary-default, $opacity) !important
    color: rgba(var(--color-primary), $opacity) !important

@mixin color-secondary($opacity: 1)
    color: rgba(const.$col-secondary-default, $opacity)
    color: rgba(var(--color-secondary), $opacity)

//border-color
@mixin border-color-primary($opacity: 1, $side: 'all')
    $color: rgba(var(--color-primary), $opacity)
    $color-fallback: rgba(const.$col-primary-default, $opacity)

    @if $side == 'all'
        border-color: $color-fallback
        border-color: $color
    
    @if $side == 'left' 
        border-left-color: $color-fallback
        border-left-color: $color
        


@mixin border-color-secondary($opacity: 1)
    border-color: rgba(const.$col-secondary-default, $opacity)
    border-color: rgba(var(--color-secondary), $opacity)

//background-color
@mixin background-color-primary($opacity: 1)
    background-color: rgba(const.$col-primary-default, $opacity)
    background-color: rgba(var(--color-primary), $opacity)

@mixin background-color-secondary($opacity: 1)
    background-color: rgba(const.$col-secondary-default, $opacity)
    background-color: rgba(var(--color-secondary), $opacity)

@mixin background-color-navbar($opacity: 1)
    background-color: rgba(const.$col-navbar-default, $opacity)
    background-color: rgba(var(--color-navbar), $opacity)
    