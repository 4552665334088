@use "/src/sass/import"

.add-button
	align-self: center
	margin: 10px 0 20px
	width: 100%
	&:hover
		opacity: 1

.add-button-inner
	width: 100%
