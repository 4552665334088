@use "/src/sass/import"

.date-input
	display: flex
	align-items: center

.icon-calendar
	font-size: 1.7em
	color: import.$col-grey-text
	cursor: pointer
	&:before
		margin-right: 1px