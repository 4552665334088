@use "/src/sass/import"

.answer-time
	display: flex
	align-items: center
	width: 100%

.icon-clock
	font-size: 1.3em
	color: import.$col-grey-text
	cursor: pointer
	&:before
		margin-right: 6px

.help-text
	color: import.$col-red
	margin-left: 10px