.row
	display: flex
	margin-right: -100px // hack for common form
	margin-top: 10px

.select
	width: 200px !important
	margin: 0 10px 0 0 !important

.number-input
	width: 80px !important
	margin: 0 !important
