@use "/src/sass/import"

.button-container
	display: flex
	align-items: center

.credential
	display: flex
	max-width: 400px
	flex-direction: column
	border-radius: 4px
	padding: 10px
	border: 1px solid import.$col-grey-light
	border-left: 5px solid
	box-shadow: 0 0 5px 0 rgba(import.$col-black, 0.1)
	margin-bottom: 20px

.top-row
	display: flex
	justify-content: space-between

.enabled-text
	color: import.$col-green

.disabled-text
	color: import.$col-red

.date
	color: import.$col-grey-text