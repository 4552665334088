@use "/src/sass/import"
$width: 250px

.container
	position: fixed
	z-index: 2000
	bottom: 0px
	right: 0px

.notification
	margin: 10px 3px 3px
	color: import.$col-white
	padding: 20px
	border-radius: 5px
	width: $width

.positive
	background-color: rgba(import.$col-green, .9)
.error
	background-color: rgba(import.$col-red, .9)
.neutral
	background-color: rgba(import.$col-black, .9)

\:global 
	.notification-animation-enter
		transform: translate($width + 100px, 0)

	.notification-animation-enter-active
		transform: translate(0,0)
		transition: all 300ms ease-in-out

	.notification-animation-exit
		// transform: translate(0,0)
		max-height: 120px
		opacity: 1

	.notification-animation-exit-active
		max-height: 0px
		opacity: 0
		// transform: translate($width + 100px, 0)
		transition: all 500ms ease-in-out