@use "/src/sass/import"
.container-outer
	display: flex
	align-items: center
	justify-content: space-between
	padding: 10px 0
	border-bottom: 1px solid import.$col-grey-border

.container-outer-removed
	opacity: 0.8
	background-color: #f0c2c2

.container
	display: flex
	align-items: center
	cursor: pointer
	width: 100%
	&:hover
		opacity: 0.8

.details
	display: flex
	justify-content: space-between
	align-items: center
	width: 100%

.about-text-2
	color: import.$col-grey-text

.email-text
	font-weight: 600

.right-container
	display: flex
	align-items: center

.type-label
	padding: 1px 7px
	border-radius: 4px
	margin-right: 10px
	color: import.$col-white

.type-custom
	@extend .type-label
	background-color: #614adf
.type-user
	@extend .type-label
	background-color: #b62e92
.type-record
	@extend .type-label
	background-color: #12addc

.cross
	&:hover
		cursor: pointer

.hide-cross
	pointer-events: none
	opacity: 0
