@use "/src/sass/import"

.container
	height: 100vh
	overflow-y: scroll
	background-color: import.$col-grey-xlight
	flex-basis: 330px
	flex-grow: 0

.inner
	padding: 30px 30px 0px
