.container
	display: flex
	flex-direction: column
	flex-wrap: wrap
	justify-content: center
	align-items: center
	box-sizing: border-box

.selector
	display: flex
	flex-wrap: wrap
	justify-content: center

.option
	margin-bottom: 5px
	cursor: pointer
	box-sizing: content-box
	border-right: 5px solid white
	background-color: grey
	width: 27px
	height: 30px
	transition: all 0.2s linear
	&:hover
		transform: scaleY(1.1)

.option:last-child
	border-top-right-radius: 100px
	border-bottom-right-radius: 100px
	border-right: none

.option:first-child
	border-top-left-radius: 100px
	border-bottom-left-radius: 100px

.label
	padding: 0px 6px 0px
	width: 100%
	font-weight: 500
	text-align: center