@use "/src/sass/import"

.container
	margin-top: 0px
	width: 100%
	display: flex
	flex-direction: column
	box-sizing: border-box
	padding: 30px 7%
	max-width: calc(100vw - import.$dashboard-left-menu-width - import.$navbar-width)
	overflow-x: auto

.header-row
	width: 100%
	background-color: import.$col-grey-xlight
	border-radius: 6px
	font-weight: 600
	display: flex

.cell
	padding: 15px 20px
	display: flex
	cursor: pointer

.order-icon
	margin-left: 5px

.action-container
	display: flex
	justify-content: space-between

.search-container
	display: flex
	align-items: center

.search-input
	width: 100px !important
	border: 0px !important
	font-size: 1rem !important
	&:placeholder
		text-align: right


