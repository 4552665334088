@use "/src/sass/import"

.admin-panel
	display: flex
	flex-direction: column

.current-org
	margin-bottom: 20px
	display: flex
.org
	font-weight: 600
	margin: 0px 10px

.search-row
	display: flex
	align-item: center