@use "../utils/_const" as const

.page-form-container
	display: flex
	flex-direction: column
	align-items: center
	width: 100%
	margin: 30px 0px
	justify-content: center

.page-form
	display: flex
	flex-direction: column
	align-items: center
	min-width: 300px


.common-form
	display: flex
	flex-direction: column
	align-items: center
	max-width: 400px
	width: 100%

.common-form-title
	font-size: 2rem
	margin-top: 20px 
	margin-bottom: 10px

.common-form-subtitle
	font-size: 1.2rem
	margin-bottom: 25px

.common-form-input
	margin: 20px 0px
	width: 100%


.form-button
	margin: 30px 0 20px
	align-self: center
