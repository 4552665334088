@use "/src/sass/import"

.folder-contents
	border-left: 1px solid import.$col-grey-border
	padding-left: 20px

.row
	display: flex
	align-items: center
	justify-content: space-between
	padding: 6px 0
	min-height: 20px
	border-bottom: 1px solid rgba(white, 0)
	&:hover
		border-bottom: 1px solid import.$col-grey-border

.link
	transition: all 0.2s ease
	color: import.$col-black
	&:hover
		@include import.color-secondary

.name-container
	cursor: pointer
	display: flex
	align-items: center

.icon-add-circle-name-container
	transform: scale(1.4)
	@include import.color-primary

	&:hover
		transform: scale(1.5)


.bin-icon
	cursor: pointer
	margin-right: 4px
	&:hover
		color: import.$col-red

.create-section			
	display: flex
	align-items: center
	flex-direction: column		
	margin-top: 40px


.instructions
	margin: 20px
	color: import.$col-grey-text

.icon-add-circle-instructions:before
	margin-right: 0px
	@include import.color-primary

.upload-row
	display: flex
	flex-wrap: wrap
	align-items: center
	button	
		margin-left: 20px

.add-container
	width: 100%
	display: flex
	align-items: center
	justify-content: center

.add-row
	display: flex
	align-items: center
	width: 100%
	justify-content: space-around
	max-width: 285px

.dropzone-container
	background-color: transparent