@use "/src/sass/import"

.container
	display: flex
	flex-direction: column
	align-items: stretch
	padding: 30px 7%

.days
	display: grid
	grid-template-columns: repeat(7, minmax(0, 1fr))
	box-sizing: border-box
	margin-bottom: 10px

.day
	display: flex
	justify-content: center

.loading-bar-container
	display: flex
	align-items: flex-end
	height: 5px
	margin: 0px 13px -2px

.month
	display: grid
	grid-template-columns: repeat(7, minmax(0, 1fr))
	box-sizing: border-box
	border-radius: 16px
	box-shadow: 0px 0px 50px rgba(0,0,0,0.08)

.title-container
	display: flex
	justify-content: center
	align-items: center
	margin-bottom: 20px

.title-text
	font-size: 1.5rem
	min-width: 200px
	text-align: center

.arrow
	padding: 10px
	cursor: pointer
	color: import.$col-black

.week
	display: flex




