@use "/src/sass/import"

.title
    font-size: 1.1rem
    font-weight: 600
    margin-bottom: 5px
    color: import.$col-black

.link
    padding: 6px 0px
    display: flex
    color: import.$col-black
    cursor: pointer
    &:hover 
        @include import.color-primary

.active
    @include import.color-primary

