@use "/src/sass/import"

.no-content
	color: import.$col-grey-text

.form-input
	width: 100%
	font-size: 1rem !important

.answer-text-disabled
	width: 100%
	margin-left: 4px