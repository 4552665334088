@use "/src/sass/import"

.container
    width: import.$dashboard-left-menu-width
    display: flex
    flex-direction: column
    flex-grow: 0
    padding: 10px 15px
    overflow-y: auto

.record-name
    font-weight: 400
    font-size: 1.3rem

.record-position
    margin: 5px 0
    
.archived
    color: import.$col-red
.record-team
    color: import.$col-grey-text