.loading-bar-container
	height: 0
	overflow: visible

.dropdown-placeholder
	width: 24px

\:global 
	.new-pdf-animation-enter
		max-height: 0px
		opacity: 0

	.new-pdf-animation-enter-active
		max-height: 200px
		opacity: 1
		transition: all 300ms ease-in-out

	.new-pdf-animation-exit
		max-height: 300px
		opacity: 1


	.new-pdf-animation-exit-active
		max-height: 0px
		opacity: 0
		transition: all 300ms ease-in-out