@use "/src/sass/import"

.container
	display: flex
	align-items: center
	cursor: pointer

.single-value-container
	margin: 2.5px 5px

.other-container
	border-top: 1px solid import.$col-grey-border
	padding-top: 10px

.color-dot
	width: 16px
	height: 16px
	flex-shrink: 0
	border-radius: 10px
	margin-right: 6px
	margin-left: -6px

.label
	height: auto