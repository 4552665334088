// WARNING! Webpack will tree shake :global attributes.
// ensure that something from this file is actually imported!

.container
	width: 100%

\:global
	.answer-animation-enter
		opacity: 0
		transform: scale(0.6)
		max-height: 0px

	.answer-animation-enter-active
		max-height: 130px
		transform: scale(1)
		opacity: 1
		transition: all 0.4s ease

	.answer-animation-exit
		max-height: 130px
		opacity: 1

	.answer-animation-exit-active
		max-height: 0px
		transform: scale(0.6)
		transition: all 0.4s ease
		opacity: 0

	.answer-animation-exit-done
		max-height: 0px
		transform: scale(0.6)
		opacity: 0
