@use "/src/sass/import"
	
.icon-row
	display: flex
	flex-wrap: wrap
	margin-left: -10px
	margin-right: -13px

.filter-container
	display: flex
	align-items: center
	justify-content: space-between
	margin-top: 10px

.search-icon-mover
	transition: all 0.2s ease

.search-icon-mover-active
	transform: translateY(45px)

.search-input-container
	max-height: 0px
	opacity: 0
	overflow: hidden
	transition: all 0.4s ease

.show-search-input-container
	opacity: 1
	max-height: 50px

.search-input
	background-color: transparent
	border: 0px !important
	width: 100% !important
	padding-left: 38px !important
	padding-top: 7px !important
