@use "/src/sass/import"

.container-outer
	flex-grow: 0
	flex-shrink: 0
	width: import.$dashboard-left-menu-width

.container
	background-color: import.$col-grey-xlight
	box-sizing: border-box
	height: 100vh
	overflow-y: auto
	padding: 20px
	display: flex
	flex-direction: column

.title
	font-weight: 600
	font-size: 1.3rem

.add-link
	margin: 20px 0

