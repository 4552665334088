@use "/src/sass/import"

.field-title-container
	display: flex
	align-items: center
.field-title
	font-size: 1.1rem
	font-weight: 400
	margin: 3px 0px
.field-subtitle
	margin: 3px 0px
	font-size: 0.8rem
	color: import.$col-grey-text
	margin-top: 0px