@use "/src/sass/import"

.pdf-container
	margin: 0px 0
.pdf
	display: flex
	justify-content: space-between
	align-items: center
	padding: 3px 10px 4px 18px
	border-radius: 8px
	background-color: import.$col-white
	margin-bottom: 12px
	box-shadow: 0.6px 1.3px 1.3px hsl(0deg 0% 0% / 0.38)
	&:hover
		box-shadow: 0.3px 0.6px 0.6px hsl(0deg 0% 0% / 0.49)
	
.pdf-text-container
	padding: 4px 0px
	display: flex
	flex-direction: column
	
	&:hover
		@include import.color-primary

.icon
	&:before
		font-size: 1.2rem !important
		margin-right: 4px
		margin-left: -3px

.pdf-email-input-container
	margin: 15px 0 40px

.badge
	display: flex
	align-self: flex-start
	background-color: import.$col-grey
	color: import.$col-white
	border-radius: 50px
	font-size: 0.8rem
	margin-top: 3px
	padding: 0px 14px

.link
	color: import.$col-black