.container
	margin-top: 10px
	display: flex
	flex-direction: column
	padding: 30px 7%


.event-lists
	display: flex
	flex-direction: column
	align-items: center