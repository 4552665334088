@use "/src/sass/import"

.category
	cursor: pointer
	&:hover
		color: import.$col-grey-text

.category-selected
	@include import.color-primary

.subcategory
	display: flex
	padding: 0px 10px 10px
	cursor: pointer
	color: import.$col-black

.subcategory-selected
	@include import.color-primary