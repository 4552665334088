@use "/src/sass/import"

.answer-dropdown
	width: 100%

.answer-dropdown-other-container
	width: 100%
	display: flex

.input
	border-color: import.$col-grey-border