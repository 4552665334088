.title-row
	display: flex
	justify-content: center
	align-items: center
	margin-bottom: 20px

.title
	font-size: 2rem
	min-width: 320px
	text-align: center
	font-weight: bold

.switch-year
	padding: 15px
	font-size: 1.2rem

