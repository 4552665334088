@use "/src/sass/import"

.card
	padding: 20px 40px
	margin: 30px
	background-color: import.$col-white
	width: 480px
	border-radius: 3px
	box-shadow: 4px 4px 19px rgba(0,0,0,0.12)
	display: flex
	flex-direction: column


.card-enabled
	@extend .card
	cursor: pointer
	transition: transform 0.13s linear
	&:hover
		transform: scale(1.02)

.card-disabled
	@extend .card
	background-color: import.$col-grey-xlight

.title-row
	display: flex
	align-items: center
.module-icon
	font-size: 2em

.description
	margin-top: 10px

.grow
	flex-grow: 10

.button-row
	margin-top: 20px
	width: 100%
	display: flex
	justify-content: center