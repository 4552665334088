@use "/src/sass/import"

.container
	@extend .hide-scrollbar
	display: flex
	flex-direction: column
	overflow-x: hidden
	overflow-y: auto
	max-height: 200px
	padding: 0px 4px

	.day-of-month
		align-self: center
		padding: 2px 5px
		margin: 4px
		display: flex
		align-items: center
		justify-content: center
		border-radius: 10px
		color: import.$col-black

	.dim
		color: import.$col-grey-text

	.isToday
		@include import.background-color-primary
		color: white

	border-left: 1px solid import.$col-grey-light
	border-bottom: 1px solid import.$col-grey-light
	&:nth-child(-n + 7)
		border-top: 1px solid import.$col-grey-light
	&:nth-child(7n)
		border-right: 1px solid import.$col-grey-light

	&:nth-child(1)
		border-top-left-radius: 15px
	&:nth-child(7)
		border-top-right-radius: 15px
	&:nth-last-child(1)
		border-bottom-right-radius: 15px
	&:nth-last-child(7)
		border-bottom-left-radius: 15px
	

	.answers
		min-height: 70px

