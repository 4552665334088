@use "/src/sass/import"
	
.container-outer
	background-color: import.$col-grey-light
	padding: 30px
	width: 100%
	display: flex
	align-items: center
	flex-direction: column
	height: 100vh
	overflow-y: auto


.container
	display: flex
	align-items: center
	flex-direction: column

.card-container
	display: flex
	flex-wrap: wrap
	justify-content: center
