.container
	margin-left: 10px
	&:hover .show-details
		opacity: 0.6

.field-title-row
	display: flex

.show-details
	cursor: pointer
	transition: opacity linear 0.2s
	opacity: 0