@use "/src/sass/import"

.label
	font-size: 1.1rem
	font-weight: bold
	margin-top: 20px
	margin-bottom: 16px

.pdf-list
	margin-bottom: 30px
	
.pdf
	margin: 10px
	display: flex
	align-items: center

.text-section
	display: flex
	flex-direction: column
	margin-left: 20px

.form
	font-weight: bold

.created
	color: import.$col-grey-text
	