@use "/src/sass/import"

.container
	width: 100%
	display: flex
	align-items: center

.clickable
	flex: 1
	width: 0px // this is a css hack to get ellipsis working for tables, otherwise we'd need js
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis

.edit-button
	cursor: pointer
	margin: 0px 5px
	font-size: 1.3rem
	color: import.$col-grey-text
	&::before
		margin-right: 0px

.input-container
	width: 100%
	display: flex
	flex-direction: column