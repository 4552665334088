@use "sass:color"
@use "/src/sass/import"
	
.button
	color: import.$col-black
	font-size: 1.5rem
	border-radius: 50%
	margin: 2px 10px 2px 5px
	transition: all 0.2s ease
	width: 28px
	height: 28px

	&:before
		margin-right: 0
		position: relative
		top: -3px
		left: 1.5px

	&:hover
		opacity: 0.7
		transform: scale(1.1)

	
.button-matched
	background-color: import.$col-green
	color: import.$col-white

.button-matched-parent
	background-color: color.mix(import.$col-green, rgba(0,0,0,0), 30%)
	color: import.$col-white

.button-matched-matched-parent
	color: import.$col-green !important
