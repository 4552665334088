@use "/src/sass/import"

@keyframes shimmer
    0%
        background-color: import.$col-grey-text
    50%
        background-color: import.$col-grey-medium
    100%
        background-color: import.$col-grey-text


.placeholder
    background-color: import.$col-grey-medium
    // background: linear-gradient(270deg, green, #647a93);
    // background-size: 400% 400%
    opacity: 0.1
    border-radius: 5px
    height: 60px
    width: 100%
    animation: shimmer 1s linear infinite

    margin-top: 20px
    margin-bottom: 20px

    