@use "/src/sass/import"

.section
	margin-top: 25px
	margin-bottom: 0

.field
	margin: 10px
	display: block
	color: import.$col-black
	
.title
	font-weight: 600

.inline-content
	margin-left: 10px

.block-content
	display: flex
	margin: 4px 0

	
