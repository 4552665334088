@use "/src/sass/import"

.public-create-record
	display: flex
	flex-direction: column
	align-items: center
	input
		margin: 30px 0px

.choice-section
	display: flex
	flex-direction: column
	align-items: center
	margin: 20px 0 50px


.choice
	border-radius: 10px
	padding: 3px 20px
	margin: 5px 0px
	width: 200px
	border: 3px solid import.$col-grey-light
	cursor: pointer
	transition: all 0.15s linear

	&:hover
		border-color: import.$col-grey


.selected
	@include import.border-color-primary
	@include import.background-color-primary($opacity: 0.2)