body
	margin: 0px
	font-family: 'Open Sans', sans-serif
	min-width: 450px
	font-weight: 400
	box-sizing: border-box
	line-height: 1.6
	font-size: 0.9rem

a, p
	text-decoration: inherit

b
	font-weight: 600

a
	color: inherit
	cursor: pointer
	&:hover
		opacity: 0.8

h1,h2,h3,h4,h5,h6
	font-weight: 400

h1
	font-size: 2.5rem
	margin: 2.0rem 0

h2
	font-size: 2.2rem
	margin: 1.8rem 0

h3
	font-size: 2.0rem
	margin: 1.5rem 0

h4
	font-size: 1.8rem
	margin: 1.3rem 0

h5
	font-size: 1.5rem
	margin: 1.0rem 0

h6
	font-size: 1.3rem
	margin: 0.8rem 0
