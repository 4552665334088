@use "/src/sass/import"

.header-container
	background-color: import.$col-grey-xlight
	height: 42px

.top-row-spacer
	height: 15px
	border-right: 1px solid import.$col-grey-light

.month-title
	height: 25px

.month-jan
	border-left: 1px solid black

.current
	background-color: import.$col-blue
	color: import.$col-white
	font-weight: bold
	border-radius: 5px

.search-container
	display: flex
	padding-left: 10px

.search-input
	padding-left: 8px
	margin-left: 0
	border: none
	background-color: transparent
