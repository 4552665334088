.title-row
	display: flex
	align-items: center
	justify-content: space-between
	width: 100%

.button-group
	display: flex
	align-items: center
	justify-content: flex-end
	gap: 10px
