@use "/src/sass/import"
	
.container
	max-width: 700px
	display: flex
	width: 100%
	flex-direction: column
	align-items: center
	margin-top: 40px

.load-more-button-container
	display: flex
	justify-content: center
	align-items: flex-start
	margin-top: 10px

.load-more-button
	color: import.$col-grey-text
	border-color: rgba(0,0,0,0)
	&:hover
		background-color: rgba(0,0,0,0)
		@include import.border-color-primary
		@include import.color-primary