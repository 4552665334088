@use "../utils/_const" as const
@use "../utils/_color" as color

.muted
	color: const.$col-grey-text 

.classic-anchor
	@include color.color-primary
	
.error
	color: const.$col-red

// Remove number input buttons
.number-input::-webkit-outer-spin-button,.number-input::-webkit-inner-spin-button
	appearance: none
	margin: 0

.number-input
	-moz-appearance: textfield
