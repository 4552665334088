@use "/src/sass/import"

.dropzone-text
	text-align: center
	font-size: 1.1rem
	color: import.$col-grey-text
	width: 100%

.dropzone-inner
	display: flex
	align-items: center
	flex-direction: column
	text-align: center

.dropzone-container
	width: 100%
	margin-top: 20px
	display: flex
	flex-direction: column
	align-items: center

.dropzone
	width: 130px
	height: 130px
	background-color: import.$col-grey-xlight
	display: flex
	cursor: pointer
	border-radius: 4px
	align-items: center
	justify-content: center
	&:hover
		.icon
			color: import.$col-black !important

.icon
	font-size: 4rem
	line-height: 4rem
	color: import.$col-grey-text
	&::before
		margin-right: 0px

.image-container
	width: 100%
	display: flex
	justify-content: center

.image
	width: 200px
	height: 200px
	background-color: rgba(0,0,0,0.04)
	background-size: contain
	background-position: center
	background-repeat: no-repeat

.cancel-icon
	margin: 10px
	position: relative
	float: right
	cursor: pointer
	&:hover
		color: import.$col-red

.common-select
	width: 100%
	margin: 20px 0px