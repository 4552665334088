@use "/src/sass/import"

.radio-row
    display: flex
    flex-wrap: wrap
    justify-content: center

.radio-item
    margin-left: 10px

.radio-text
    margin-left: 5px