@use "/src/sass/import"

.container
	display: flex
	width: 100%
	align-items: center
	justify-content: center

.title
	color: import.$col-white
	margin-top: 15px
	
.inner
	color: import.$col-white
	background-color: black
	background: linear-gradient(70deg, rgb(196, 72, 72), rgb(97, 105, 185))
	display: flex
	margin: 50px 0px
	padding: 00px 20px 30px 20px
	align-items: center
	justify-content: center
	flex-direction: column
	border-radius: 10px


